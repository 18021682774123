@use 'common'

.wrapper
	width: 100%
	padding: 10px 0
	border-radius: 10px
	border: 1px solid #C8C8C8
	background-color: #fff
	min-width: 300px
	margin-right: 40px
	max-width: 300px
	position: sticky
	top: 20px
	@media (max-width: 1000px)
		max-width: 100%
		margin: 20px 0
		position: relative
		top: unset
		min-width: 280px

.list
	list-style: none
	padding: 0
	margin: 0
	button
		color: #000
	> li
		border-bottom: 1px solid #EBEBEB
		padding: 14px 20px 10px 20px
		&.active
			> button
				color: #1AA97C
				span
					transform: rotate(-45deg)
			ul
				display: block
		button
			width: 100%
			display: flex
			align-items: baseline
			padding: 0
			border: none
			background-color: transparent
			font-size: 17px
			font-weight: 700
			font-family: common.$harmoniaSans
			justify-content: space-between
			line-height: 1
			cursor: pointer
		> button > span
			position: relative
			display: inline-block
			width: 7px
			height: 7px
			border-left: 1px solid #9B9B9B
			border-bottom: 1px solid #9B9B9B
			transform: rotate(-135deg)
			transition: all .35s ease
		ul
			list-style: none
			padding: 30px 0
			display: none
			li + li
				margin-top: 10px
			li
				&.active
					button
						color: #1AA97C
					span
						background-color: #1AA97C
						border-color: #1AA97C
				button
					font-size: 16px
					font-family: common.$karla
					justify-content: flex-start
					align-items: center
					font-weight: 400
					text-align: left
					span
						width: 20px
						height: 20px
						display: inline-block
						border: 1px solid #1D1D1B
						border-radius: 3px
						margin-right: 20px

	> li:last-child
		border-bottom: none
