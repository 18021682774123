@use 'common'

.wrapper
	--Button-fill-color: transparent
	--Button-text-color: #{common.$grey600}

	border: 1px solid
	border-color: transparent
	border-radius: .625rem
	font-size: 1.125em
	line-height: 1.22
	padding: .875rem 1.125rem
	font-weight: 700
	text-decoration: none
	display: inline-block
	text-align: center
	background-color: var(--Button-fill-color)
	color: var(--Button-text-color)
	transition-property: background-color, color, opacity
	transition-duration: .2s
	transition-timing-function: ease-in-out
	outline: unset

	&:disabled
		pointer-events: none
		opacity: .5

	&.is_fill_empty
		&.is_border
			border-color: #{common.$grey200}
			color: #{common.$groundBlack}

		&:hover, &:focus-visible
			background-color: common.$grey200
			color: common.$groundBlack

	&.is_fill_green100
		--Button-fill-color: #{common.$green100}
		--Button-text-color: #{common.$green600}

		&:hover, &:focus-visible
			background-color: common.$green600
			color: #FFFFFF

	&.is_fill_green600
		--Button-fill-color: #{common.$green600}
		--Button-text-color: #FFFFFF

		&:hover, &:focus-visible
			background-color: common.$green100
			color: common.$green600

	&.is_fill_greenButton
		--Button-fill-color: #{common.$greenButton}
		--Button-text-color: #FFFFFF

		&:hover, &:focus-visible
			background-color: common.$green100
			color: common.$green600

	&.is_fill_grey100
		--Button-fill-color: #{common.$grey100}
		--Button-text-color: #{common.$groundBlack}

		&:hover, &:focus-visible
			background-color: common.$groundBlack
			color: #FFFFFF

	&.is_fill_groundBlack
		--Button-fill-color: #{common.$groundBlack}
		--Button-text-color: #FFFFFF

		&:hover, &:focus-visible
			background-color: common.$grey100
			color: common.$groundBlack

	&.is_fill_red600
		--Button-fill-color: #{common.$red600}
		--Button-text-color: #FFFFFF

		&:hover, &:focus-visible
			background-color: common.$red800

	&.is_border
		border-color: var(--Button-text-color)

	&.is_upperCase
		text-transform: uppercase

	&.is_fontWeight_default
		//

	&.is_fontWeight_400
		font-weight: 400

	&.is_fontSize_default
		//

	&.is_fontSize_small
		font-size: .875em

	&.is_size_1
		//

	&.is_size_2
		padding: 1.125rem 1.6875rem

	&.is_size_3
		padding: 5px
		border-radius: 5px

.content
	display: flex
	justify-content: center
	align-items: center
	gap: 1.5625rem

	&.is_icon_start
		//

	&.is_icon_end
		.icon
			order: 1

.icon
	display: flex
	font-size: 1.5em
