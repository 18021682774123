@use 'common'

.wrapper
	display: inline-block

.icon
	display: inline-block
	vertical-align: middle
	height: 1px
	margin-right: .25em

	svg
		position: relative
		top: -13px
