@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions

	&-go
		+normalizeSize(512, 512)

	&-arrow
		+normalizeSize(20, 11)

	&-calendar
		+normalizeSize(25, 25)

	&-climbers
		+normalizeSize(22, 23)

	&-close
		+normalizeSize(24, 24)

	&-duplicate
		+normalizeSize(23, 23)

	&-flowering
		+normalizeSize(33, 39)

	&-germination
		+normalizeSize(34, 34)

	&-growing
		+normalizeSize(41, 42)

	&-heart, &-heartFilled
		+normalizeSize(16, 14)

	&-imageIllustration
		+normalizeSize(37, 36)

	&-lock
		+normalizeSize(18, 20)

	&-message
		+normalizeSize(25, 25)

	&-pdfIllustration
		+normalizeSize(37, 36)

	&-pencil
		+normalizeSize(20, 20)

	&-pending
		+normalizeSize(15, 15)

	&-plant
		+normalizeSize(21, 31)

	&-questionMark
		+normalizeSize(25, 25)

	&-reject
		+normalizeSize(15, 15)

	&-ripening
		+normalizeSize(33, 41)

	&-sun
		+normalizeSize(33, 33)

	&-airhumidity
		+normalizeSize(33, 33)

	&-addPlant
		width: 38px
		height: 38px

	&-moisture
		+normalizeSize(33, 33)

	&-planttemperature
		+normalizeSize(33, 33)

	&-soilfertilization
		+normalizeSize(33, 33)

	&-temperature
		+normalizeSize(33, 33)

	&-seedling
		+normalizeSize(36, 37)

	&-success
		+normalizeSize(15, 15)

	&-user
		+normalizeSize(25, 25)

	&-videoIllustration
		+normalizeSize(37, 36)

	&-world
		+normalizeSize(18, 18)

	&-arrowleft
		+normalizeSize(22, 18)
