@use 'common'

.wrapper
    position: relative
    margin-left: auto
.inner
    display: flex

.button
    display: flex
    padding: 0
    background-color: transparent
    border: none
    align-items: center
    padding: 5px 10px
    padding-right: 6px
    border: 1px solid #D6D6D6
    border-radius: 35px
    &.menuOpen
        .burger
            height: 20px
            span:nth-child(2)
                display: none
            span:nth-child(1)
                position: absolute
                top: 50%
                transform: rotate(45deg)
            span:nth-child(3)
                position: absolute
                top: 50%
                transform: rotate(-45deg)

.burger
    width: 20px
    height: 11px
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-right: 10px
    position: relative
    overflow: hidden
    span
        width: 100%
        height: 1px
        background-color: #000

.image
    width: 30px
    height: 30px
    border-radius: 50%
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyOCAyOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQiIGN5PSIxMS4yODMiIHI9IjQuMDc1OSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2VMaW5lY2FwPSJyb3VuZCIgc3Ryb2tlV2lkdGg9IjIiLz4KPHBhdGggZD0ibTIyLjE1MiAyMy4xMTFjLTAuNDgwOS0xLjQ0NDQtMS41NDA0LTIuNzIwOC0zLjAxNDMtMy42MzExLTEuNDczOC0wLjkxMDQtMy4yNzk3LTEuNDAzOC01LjEzNzUtMS40MDM4cy0zLjY2MzYgMC40OTM0LTUuMTM3NSAxLjQwMzdjLTEuNDczOSAwLjkxMDQtMi41MzM0IDIuMTg2OC0zLjAxNDIgMy42MzEyIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZUxpbmVjYXA9InJvdW5kIiBzdHJva2VXaWR0aD0iMiIvPgo8L3N2Zz4=")
    background-size: 120%
    background-position: center
    background-color: rgba(#D6D6D6, .5)
    overflow: hidden
    margin-left: auto
    position: relative
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.menu
    display: none
    position: absolute
    bottom: 0
    right: 0
    background-color: #fff
    z-index: 10
    list-style: none
    padding: 0
    width: 150px
    margin: 0
    transform: translate(0, calc(100% + 10px))
    padding: 10px
    border-radius: 10px
    border: 1px solid #D6D6D6
    flex-direction: column
    &.active
        display: flex
    li + li
        margin-top: 10px
    li
        display: block
        button, a
            color: #000
            font-size: 16px
            font-family: common.$karla
        button
            padding: 0
            background-color: transparent
            color: #000
            border: none
