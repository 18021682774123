.wrapper
	width: 100%
	margin-top: var(--Flex-margin-top)
	@media(max-width: 768px)
		margin-top: calc(var(--Flex-margin-top) / 2)

.inner
	width: 100%
	display: flex
	flex-wrap: wrap

.item
	width: 100%
