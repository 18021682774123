.wrapper
	padding: 0 20px
	width: 100%
	box-sizing: border-box
	margin-top: var(--Container-margin-top)
	@media (max-width: 768px)
		margin-top: calc(var(--Container-margin-top) / 2)

	@media(min-width: 768px)
		max-width: calc(100% - 80px)
		margin: 0 auto
		padding: 0
		margin-top: var(--Container-margin-top)

	@media(min-width: 1200px)
		max-width: 1100px
		&.wide
			max-width: unset
			padding: 0 60px
		&.slim
			max-width: 816px
