@use 'common'

.wrapper
	width: 100%
	background-color: #fff
	padding: 20px 40px
	position: relative
	z-index: 2
	@media (max-width: 768px)
		padding: 20px

.inner
	display: flex
	align-items: center
	position: relative

.search
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)

.logo
	display: flex
	align-items: flex-end
	margin-right: 40px
	position: relative
	top: -.35em
	span
		font-size: common.$harmoniaSans
		font-weight: 700
		font-size: 18px
	svg
		width: 90px

.search
	input
		background-color: common.$grey100
		border-radius: 50px
		outline: none
		border: none
		box-shadow: none
		height: 46px
		padding: 20px 40px 20px 20px
		font-size: 16px
		color: common.$grey600
		&::-webkit-search-cancel-button
			display: none
		&:focus
			outline: none
			border: 1px solid #000

.searchButton
	border: none
	padding: 0
	background-color: transparent
	position: absolute
	top: 50%
	right: 10px
	transform: translateY(-50%)

.user
	position: relative
	margin-left: auto

.primaryNavItems
	display: flex
	gap: 1em
