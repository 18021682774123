@use 'common'

.wrapper
	display: inline-block

.main
	display: flex
	align-items: center
	gap: .4375rem
	padding: .5rem .625rem
	border-width: 1px
	border-style: solid
	border-radius: .3125rem
	font-size: .875em
	line-height: 1.214

	&.is_rejected
		color: common.$red800
		border-color: common.$red800
		background-color: common.$red100

	&.is_pending
		color: common.$yellow800
		border-color: common.$yellow800
		background-color: common.$yellow100

	&.is_published
		color: common.$greenButton
		border-color: common.$greenButton
		background-color: common.$green100

.icon
	display: flex

.label
	font-family: common.$headingFont
	font-weight: 700
