@use 'common'

.wrapper
	padding: 10px 0 20px
	width: 100%
	border-top: 1px solid #C8C8C8
	margin-top: 50px

.inner
	display: flex
	align-items: baseline

	.right
		margin-left: auto

.copy
	margin-left: 30px
