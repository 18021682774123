@use 'common'

$buttonPaddingInline: .1875rem

.wrapper
	display: inline-block

	&.is_background
		.button
			font-size: .875em
			border-radius: 1.25rem
			background-color: #FFFFFF

		.icon
			font-size: 1.1em
			margin-left: .625rem - $buttonPaddingInline

		.count
			margin-right: .3125rem - $buttonPaddingInline

.button, .notButton
	display: flex
	align-items: center
	font-family: common.$headingFont
	background-color: transparent
	border: unset
	color: common.$grey600

.notButton
	font-size: inherit
	line-height: inherit
	font-family: inherit
	gap: .625rem

	.icon
		font-size: 1.2em

.button
	gap: .9375rem
	padding-block: .3125rem
	font-size: 1.125em
	font-weight: 700

	.count
		font-size: 1.125em
		line-height: 1.2

	&:hover
		.icon
			.is_hovered
				visibility: initial
				opacity: 1

.icon
	position: relative
	font-size: 1.1em
	display: flex
	z-index: 0

	&.is_active

		.is_hovered
			opacity: 1
			visibility: initial

	&.is_color_default
		//

	&.is_color_red
		.is_hovered
			color: common.$red600

	.is_hovered
		position: absolute
		inset: 0
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: .2s
		z-index: 0

.count
	//
