@use 'common'

.search
	position: relative
	z-index: 2

	input
		background-color: common.$grey100
		border-radius: 50px
		outline: none
		border: none
		box-shadow: none
		height: 46px
		padding: 20px 40px 20px 20px
		font-size: 16px
		color: common.$grey600
		border: 1px solid transparent

		&::-webkit-search-cancel-button
			display: none
		&:focus
			outline: none
			border: 1px solid #000

.searchButton
	border: none
	padding: 0
	background-color: transparent
	position: absolute
	top: 50%
	right: 10px
	transform: translateY(-50%)

.wrapper
	display: flex
	flex-grow: 1
	padding: 0 1em
	justify-content: center

	&.viewMobile
		position: relative
		top: unset
		left: unset
		transform: none
		margin: 0 auto
		input
			background-color: #fff

.container
	position: relative

.results
	position: absolute
	z-index: 1
	bottom: 0
	left: 0
	transform: translate(0, 100%)
	background-color: #fff
	width: 100%
	padding: 0
	list-style: none
	padding: 30px 10px 10px 10px
	border-radius: 0 0 10px 10px
	border: 1px solid #D6D6D6
	border-top: none

	li + li
		margin-top: 10px

.searchItem
	display: flex
	align-items: center
	h3
		padding: 0
		margin: 0 0 0 10px
		line-height: 1em
		font-size: 16px
		font-weight: 600

.searchItemImage
	width: 30px
	height: 30px
	overflow: hidden
	position: relative
	border-radius: 5px
	overflow: hidden
