@use 'common'

$gap: .625rem

.wrapper
	display: flex
	align-items: center
	gap: $gap
	font-family: common.$headingFont
	font-size: .875em
	line-height: 1.214
	font-weight: 700
	color: common.$grey600

	&.is_size_small
		//

	&.is_size_medium
		font-size: 1.125em

		.image
			width: 1.875rem

	&.is_highlighted

		.name
			color: common.$green400

		.link
			&:hover, &:focus-visible
				.name
					color: common.$grey600

	&.is_size_big
		font-size: 1.5625em

		.image
			width: 2.8125rem

.label
	display: none

.link
	outline: none

	&:hover, &:focus-visible
		.image
			&In
				transform: scale(1.1)

				&::after
					visibility: initial
					opacity: 1

		.name
			color: common.$green400

.image
	position: relative
	width: 1.25rem
	aspect-ratio: 1 / 1
	border-radius: 50%
	overflow: hidden
	z-index: 0

	&In
		width: 100%
		height: 100%
		transition: transform .4s
		background-color: common.$grey200

		&::after
			content: ''
			position: absolute
			inset: 0
			background-color: rgba(#000000, .2)
			visibility: hidden
			opacity: 0
			transition-property: visibility, opacity
			transition-duration: .2s
			z-index: 0

.main
	display: flex
	align-items: center
	gap: $gap

.name
	transition: color .2s
	margin-bottom: -5px
