@use 'common'

.images
	position: relative
	width: 100%
	z-index: 1

	&In
		+common.hideHorizontalScrollbarOnTouchDevice
		position: relative
		display: flex
		flex-wrap: nowrap
		scroll-snap-type: x mandatory
		z-index: 1

		&::-webkit-scrollbar
			display: none

.image
	position: relative
	flex-shrink: 0
	width: 100%
	aspect-ratio: 47 / 48
	overflow: hidden
	z-index: 0
	scroll-snap-align: center

	&In
		position: absolute
		inset: 0
		width: 100%
		height: 100%
		z-index: 0
		transition: transform .6s

		&::after
			content: ''
			position: absolute
			inset: 0
			background-color: rgba(#000000, .1)
			visibility: hidden
			opacity: 0
			transition-property: visibility, opacity
			transition-duration: .2s
			z-index: 0

.pagination
	position: absolute
	bottom: 25px
	left: 50%
	transform: translateX(-50%)
	display: flex
	justify-content: center
	align-items: center
	gap: .625rem
	z-index: 1

	&Bullet
		width: .75rem
		height: .75rem
		background-color: rgba(#FFFFFF, .5)
		border: 1px solid #FFFFFF
		border-radius: 50%
		transition: background-color .2s
		padding: unset

		&.is_active
			background-color: #FFFFFF

		&:hover, &:focus-visible
			background-color: #FFFFFF

a
	&:hover
		& ~ .images
			.imagesIn
				.image
					&In
						transform: scale(1.02)

						&::after
							visibility: initial
							opacity: 1
