@use 'common'

.wrapper
	position: relative
	//max-width: 15rem
	width: 100%
	border: 1px solid common.$grey200
	border-radius: .625rem
	overflow: hidden
	background-color: #FFFFFF
	z-index: 0

.menu
	list-style: none
	padding: 0
	margin: 0
	position: absolute
	top: 40px
	right: 10px
	background-color: #fff
	z-index: 1
	padding: 10px
	border-radius: 10px
	li + li
		margin-top: 10px

.link
	&::before
		content: ''
		position: absolute
		inset: 0
		z-index: 1

.header
	display: flex
	justify-content: space-between
	align-items: center
	padding: .8125rem .9375rem .625rem

.settings
	$buttonSpacing: 0.625rem
	margin-right: -$buttonSpacing

	&Button
		display: flex
		gap: .125rem
		align-items: center
		background-color: transparent
		border: unset
		padding: $buttonSpacing

		&Circle
			flex-shrink: 0
			width: .25rem
			height: .25rem
			background-color: common.$grey600
			border-radius: 50%
			overflow: hidden

.body
	position: relative
	z-index: 0

.content
	padding: 1.25rem .9375rem .9375rem

	&Title
		margin: 0

	&User
		position: relative
		display: inline-block
		margin-top: 1.25rem
		z-index: 1

.likes
	position: absolute
	top: .75rem
	right: .75rem
	z-index: 2
